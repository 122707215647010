import "./index.scss";

import React from "react";
import ReactDOM from "react-dom";

import { RoutedApp } from "./RoutedApp";
import reportWebVitals from "./reportWebVitals";

(async () => {
  ReactDOM.render(
    <React.StrictMode>
      <RoutedApp />
    </React.StrictMode>,
    document.getElementById("root")
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
